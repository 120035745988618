import axios from "axios";
import router from '../router/index.js';
import request from './http';
import {
	Message
} from 'element-ui'
// export const base = 'http://192.168.3.196:91/api';

// ${document.domain}
export const base = process.env.NODE_ENV === "development" ? 'http://pro.ai.shumaoheng.com/prom':`/prom`;
// export const base = process.env.NODE_ENV === "development" ? 'http://admin2.be.shumaoheng.com/api':`http://pro.ai.shumaoheng.com/api`;

// export const myGet = (url, params) => request.get(`${base}${url}`, { params })
export const myGet  = (url, params) => {
  let _url = base + url
  return new Promise((resolve, reject) => {
      axios({
          method: 'get',
          url:_url,
          params,
          headers:{
              "Content-Type": "application/json-patch+json",
              // "AUTH-TOKEN": localStorage.getItem('accesstoken')
              "Authorization": 'bearer ' + localStorage.getItem('accesstoken'),
              "X-Authorization": 'bearer ' + localStorage.getItem('refreshtoken'),
          }
      }).then(function (response) {
        if (response.headers['access-token']) { // 重新赋值token
          localStorage.setItem('accesstoken',response.headers['access-token'])
          localStorage.setItem('refreshtoken',response.headers['x-access-token'])
        }
          if (response.data.statuscode === 200) {
            resolve(response.data.data);
          } else {
            Message.error(response.data.errors)
            reject(response.data, response.code)
          }
        
      }).catch(function (err) {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.removeItem("accesstoken");
          localStorage.removeItem("activeIndex");
          Message.error(err.response.status === 401?'登录过期，请重新登录~':'您的账户异常，请联系管理员！')
          setTimeout(() => {
              router.push('/login')
          }, 1400)
        } else {
          reject(err)
        }
      })
  })
}
export const myPost = (url, params) => request.post(`${base}${url}`, { ...params });
export const  myPostJson = (url, params) => {
  let _url = base + url
  return new Promise((resolve, reject) => {
      axios({
          method: 'post',
          url:_url,
          data: params,
          headers:{
              "Content-Type": "application/json-patch+json",
              // "AUTH-TOKEN": localStorage.getItem('accesstoken')
              "Authorization": 'bearer ' + localStorage.getItem('accesstoken'),
              "X-Authorization": 'bearer ' + localStorage.getItem('refreshtoken'),
          }
      }).then(function (response) {
        if (response.headers['access-token']) { // 重新赋值token
          localStorage.setItem('accesstoken',response.headers['access-token'])
          localStorage.setItem('refreshtoken',response.headers['x-access-token'])
        }
        if(!response.config.data) {
          resolve(response.data);
        } else {
          let responseData = JSON.parse(response.config.data)
          if(responseData.exportConfig === true){ // 处理文件流 
            resolve(response);
            return false
          }
          if (response.data.statuscode === 200) {
            resolve(response.data);
          } else {
            Message.error(response.data.errors)
            reject(response.data, response.code)
          }
        }
        
      }).catch(function (err) {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.removeItem("accesstoken");
          localStorage.removeItem("activeIndex");
          Message.error(err.response.status === 401?'登录过期，请重新登录~':'您的账户异常，请联系管理员！')
          setTimeout(() => {
              router.push('/login')
          }, 1400)
        } else {
          reject(err)
        }
      })
  })
}


const service = axios.create({
  baseURL: base,
  timeout: 180000 // request timeout
})

service.interceptors.request.use(
  config => {
    config.headers['Authorization'] = 'bearer ' + localStorage.getItem('accesstoken')
    config.headers['X-Authorization'] = 'bearer ' + localStorage.getItem('refreshtoken')
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

export function httpPostExcel({
  url,
  data = {}
}) {
  return new Promise((resolve, reject) => {
    service.post(url, data, {responseType: 'blob'}).then((res) => {
      resolve(res)
    }).catch(err => {
      console.log(err)
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem("accesstoken");
        localStorage.removeItem("activeIndex");
        Message.error(err.response.status === 401?'登录过期，请重新登录~':'您的账户异常，请联系管理员！')
        setTimeout(() => {
            router.push('/login')
        }, 1400)
      } else {
        reject(err)
      }
      console.log(reject)
    })
  })
}
